import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { CompressedPixelFormat } from 'three';

import languageDetector from './languageDetector';
import i18nextConfig from './next-i18next.config';

const url = require('url');

// RULES
// 1. if no locale is specified, detect language and redirect
// 2. if locale is specified, check if the language is supported, if not, redirect to 404

export const useRedirect = (to = '') => {
	const router = useRouter();
	to = to || router.asPath;

	// language detection
	useEffect(() => {
		const detectedLng = languageDetector.detect();

		const parsedUrl = url.parse(router.asPath);
		const pathNameArray = parsedUrl.pathname.split('/').filter(Boolean);

		if (pathNameArray.length === 0) {
			parsedUrl.pathname = `/${detectedLng}`;
			router.replace(url.format(parsedUrl)); // example: '/' -> '/en'

			return;
		}

		if (i18nextConfig.i18n.locales.includes(pathNameArray[0])) {
			router.replace(to); // '/en' -> '/en/, this fixes endless redirect loop on static hosting platforms'

			return;
		} else {
			router.replace('/404/index.html'); // '/ciao' -> '/404/index.html'

			return;
		}
	});

	//   // prevent endless language redirection
	//   const pathNameArray = router.asPath.split('/').filter(Boolean)
	//   if (pathNameArray.length === 1) {
	//     router.replace(router.asPath) // Redirect to the right page...
	//     return
	//   }

	//   if (to.startsWith('/' + detectedLng) && router.route === '/404') {
	//     // prevent endless loop

	//     console.log('router replace', '/' + detectedLng + router.route)
	//     router.replace('/' + detectedLng + router.route)
	//     return
	//   }
	// })

	return <></>;
};
