import type { NextPage } from 'next';

import { useRedirect } from '@common/i18n';

const Home: NextPage = () => {
	useRedirect();

	return <></>;
};

export default Home;
